import { Controller } from "stimulus"
import $ from "jquery"
import moment from "moment"
import { fire, matches } from "@rails/ujs"
import "daterangepicker"

export default class extends Controller {
  static targets = [ 'input', 'hidden' ]

  connect() {
    if (this.hasInputTarget && matches(this.inputTarget, 'input')) {
      const input = this.inputTarget;
      const hidden = this.hiddenTarget;
      const time = this.data.get('time');

      const defaultDate = hidden.value ?
                      moment(hidden.value)
                    : this.data.get('value') ?
                      moment(this.data.get('value'))
                    :
                      moment();

      const displayFormat = time ?
                        'DD.MM.YYYY HH:mm'
                      :
                        'DD.MM.YYYY';

      const submitFormat = time ?
                       'YYYY-MM-DDTHH:mm:ssZ'
                     :
                       'YYYY-MM-DD';

      moment.locale('ru');

      let minDate = false;
      let maxDate = false;

      if (this.data.has('minDate')) {
        minDate = moment(this.data.get('minDate'), "YYYY-MM-DD");
      }

      if (this.data.has('maxDate')) {
        maxDate = moment(this.data.get('maxDate'), "YYYY-MM-DD");
      }

      let drops = 'auto';

      if (this.data.has('drops')) {
        drops = this.data.get('drops');
      }

      $(this.inputTarget).daterangepicker({
        autoUpdateInput: false,
        singleDatePicker: true,
        showDropdowns: true,
        timePicker: !!time,
        drops: drops,
        timePicker24Hour: true,
        locale: {
          format: displayFormat,
          applyLabel: 'Применить',
          cancelLabel: 'Отмена',
        },
        startDate: defaultDate,
        minDate: minDate,
        maxDate: maxDate
      }, function(start, end, label) {
        hidden.value = moment(start).format(submitFormat);
        input.value = moment(start).format(displayFormat);
        return fire(hidden, 'change');
      });

      $(this.inputTarget).on('show.daterangepicker', function(e, dp) {
        if (!input.value) {
          dp.oldStartDate = null;
          dp.oldEndDate = null;
        }
      });

      $(input).on('change', function() {
        if (!input.value) {
          hidden.value = ''
        }
      })
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      const $el = $(this.inputTarget);
      if ($el.data('daterangepicker')) { return $el.data('daterangepicker').remove(); }
    }
  }
}
