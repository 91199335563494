import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "year", "month" ]

  connect() {
    StimulusReflex.register(this)
  }

  loadEvents(event) {
    let year = $(this.yearTarget).val()
    let month = $(this.monthTarget).val()

    $('#presentation-events-details select').attr('data-controller', '')

    this.stimulate('Presentation#events_details', { serializeForm: false }, year, month)
  }
}
