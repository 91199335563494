
import { Controller } from "stimulus"
import $ from "jquery"
import Rails from "@rails/ujs"
import "mz_ui/components/toggle_columns_mz"

export default class extends Controller {

  static get targets() {
    return ['table', 'checkbox']
  }

  connect() {
    let self = this;

    this.checkboxTargets.forEach((el) => {
      const name = $(el).attr('name');
      if(localStorage.getItem(name) === null && el.dataset.defaultChecked !== 'true'){
        localStorage.setItem(name, 'false');
      }
    })
    this.checkboxTargets.forEach((el) => {
      el.closest("li.checkbox").addEventListener("click", (event) => self.checkBoxClick(event));
    })
    this.fillCheckbox();
  }

  checkBoxClick(event) {
    event.stopPropagation();
    event.target.checked = !event.target.checked
    if (event.target.tagName === 'INPUT') {
      return false;
    }
    let input = event.currentTarget.children[0].children[1]
    Rails.fire(input, 'click')
  }

  toggleColumn(event) {
    let index_column = $(event.target).attr('data-table-toggle-columns-column-index');
    localStorage.setItem(event.target.name, event.target.checked = !event.target.checked);
    $(this.tableTarget).toggleColumn(index_column, 400);
  }


  fillCheckbox() {
    if(this.tableTarget.dataset.filledCheckboxes)
      return;
    this.tableTarget.dataset.filledCheckboxes = true
    this.checkboxTargets.forEach((el) => {
      const name = el.name;
      if (localStorage.getItem(name) === 'false') {
        const index_column = $(el).attr('data-table-toggle-columns-column-index');
        $(this.tableTarget).toggleColumn(index_column);
        el.checked = false
      }
    })
  }
}
