import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  addFile(e) {
    e.preventDefault()
    let reflex = this.data.get('reflex') || 'Event::Presentation::File#add'
    this.stimulate(reflex, { serializeForm: false }, this.data.get('selector'))
  }
}
